<template>
  <div v-if="requestDetails" data-test="requests-details-section">
    <div class="float-right d-flex flex-column">
      <por-btn
        class="btn-download mb-1"
        variant="secondary"
        :disabled="generatingPdf"
        @click="onPdfBtnClick"
        data-test="btn-download"
      >
        <loader v-if="generatingPdf" class="spinner mr-2" inline />
        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" class="mr-1">
          <path
            d="M8.005 17.497v5.124H21.98v-5.124h2.795v7.92H5.21v-7.92h2.795zM16.39 4.57v9.49l3.145-3.143 1.98 1.98-6.522 6.522-6.522-6.522 1.98-1.98 3.144 3.144V4.57h2.795z"
          />
        </svg>
        <span>{{ $t('view.request.savePdf') }}</span>
      </por-btn>
      <por-btn
        v-if="requestDetails.request.evaluationStatus !== 'IN_PROGRESS'"
        class="btn-copy"
        variant="secondary"
        @click="onCopyClick"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" class="mr-1">
          <path
            d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z"
          />
        </svg>
        <span>{{ $t('view.request.copy') }}</span>
      </por-btn>
    </div>

    <div class="d-flex align-items-start justify-content-between">
      <h3>{{ typeLabel(requestDetails.type) }}</h3>
    </div>

    <ul class="list-unstyled clearfix">
      <li>
        {{ formatDate(createdDate, 'DATETIME_SHORT') }}
        {{
          requestDetails.request.applicant &&
          $t('view.request.applicant', { applicant: requestDetails.request.applicant })
        }}
      </li>
      <li v-if="requestDetails.ucrn">{{ requestDetails.ucrn }}</li>
      <li v-if="requestDetails.vessel && requestDetails.vessel.name">
        {{ requestDetails.vessel.name }}, {{ $t('generic.abbr.imo') }}
        {{ requestDetails.vessel.imo }}
      </li>
    </ul>

    <status-notice
      :variant="requestDetails.request.evaluationStatus | evaluationStatusColour"
      :title="$t('view.request.hcc.title')"
      :badge-text="requestDetails.request.evaluationStatus | evaluationStatusLabel"
    >
      <table>
        <tr
          class="mb-1"
          v-if="
            requestDetails.request.evaluationStatus === 'IN_PROGRESS' ||
            ['MOORING', 'REPAIR'].includes(requestDetails.type.toUpperCase())
          "
        >
          <td class="pr-4">
            <h6>{{ $t('view.request.hcc.state.title') }}</h6>
          </td>
          <td data-test="status">
            {{
              $t('view.request.hcc.state.date', {
                status: evaluationStatusLabel(requestDetails.request.evaluationStatus),
                datetime: formatDate(lastModDate, 'DATETIME_SHORT'),
              })
            }}
          </td>
        </tr>
        <tr
          class="mb-1 align-top"
          data-test="explanation"
          v-if="!requestDetails.request.renderedEvaluationDetails && requestDetails.request.stateReason"
        >
          <td class="pr-4 pt-1">
            <h6>{{ $t('view.request.hcc.state.reason') }}</h6>
          </td>
          <td class="text-break pt-1">
            {{ requestDetails.request.stateReason }}
          </td>
        </tr>
      </table>
      <div
        v-if="
          requestDetails.request.evaluationStatus !== 'IN_PROGRESS' &&
          !['MOORING', 'REPAIR'].includes(requestDetails.type.toUpperCase())
        "
        class="row"
      >
        <div class="col-5 pr-3">{{ $t('view.request.hcc.state.lastUpdated') }}</div>
        <div class="col-7" data-test="last-updated">
          {{ formatDate(lastModDate, 'DATETIME_SHORT') }}
        </div>
      </div>
      <request-viewer
        class="evaluation"
        v-if="!['MOORING', 'REPAIR'].includes(requestDetails.type.toUpperCase())"
        :content="renderedEvaluationDetails"
      />
      <ul class="list-inline row align-items-center" v-if="harbourMasterAttachments.length > 0">
        <li class="col-6 pb-2" v-for="(attachment, index) in harbourMasterAttachments" :key="index">
          <file-download-link
            class="py-2 mt-3 px-4 border bg-white"
            :file-name="attachment.fileName"
            :button-text="$t('view.request.attachment.button')"
            :url="
              getAttachmentURL({
                requestType: requestDetails.type,
                requestId: requestDetails.id,
                attachmentId: attachment.externalId,
              })
            "
          />
        </li>
      </ul>
      <span
        v-if="
          requestDetails.type.toUpperCase() === 'TRANSPORT' && requestDetails.request.evaluationStatus !== 'IN_PROGRESS'
        "
        data-test="disclaimer"
        v-markdown="$t('view.request.hcc.disclaimer.transport')"
      />
    </status-notice>

    <collapsible-list-item :open="true" class="my-4">
      <h6 slot="header" class="m-0 px-2 mx-1 py-2">
        {{
          $t('view.request.creation.date', {
            datetime: formatDate(createdDate, 'DATETIME_SHORT'),
          })
        }}
      </h6>
      <hr class="mx-2 m-0 mt-2" />
      <div class="py-3 px-2 mx-1">
        <outside-the-pet-view-request
          v-if="requestDetails.type.toUpperCase() === 'MOORING'"
          :request-details="combinedRequestDetailsAndVessel"
        />
        <request-repair-view-request
          v-if="requestDetails.type.toUpperCase() === 'REPAIR'"
          :request-details="combinedRequestDetailsAndVessel"
        />
        <request-viewer
          v-if="!['MOORING', 'REPAIR'].includes(requestDetails.type.toUpperCase())"
          :content="renderedDetails"
        />
        <div v-if="agentAttachments && agentAttachments.length">
          <h5>{{ $t('view.request.attachment.upload') }}</h5>
          <ul class="list-inline row align-items-center" data-test="attachment-details">
            <li class="col-6 pb-2" v-for="(attachment, index) in agentAttachments" :key="index">
              <file-download-link
                :file-name="attachment.fileName"
                :url="
                  getAttachmentURL({
                    requestType: requestDetails.type,
                    requestId: requestDetails.id,
                    attachmentId: attachment.externalId,
                  })
                "
                :button-text="$t('view.request.attachment.button')"
              />
            </li>
          </ul>
        </div>
      </div>
    </collapsible-list-item>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import CollapsibleListItem from 'poronline-shared-vue-components/components/collapsible-list-item';
import FileDownloadLink from 'poronline-shared-vue-components/components/file-download-link';
import StatusNotice from 'poronline-shared-vue-components/components/status-notice';
import PorBtn from 'poronline-shared-vue-components/components/por-btn';
import Loader from 'poronline-shared-vue-components/components/loader';
import withEchoLabels from '../../components/form/with-echo-form-labels';
import outsideThePetViewRequest from '../../forms/outside-the-pet/outside-the-pet-view-request';
import requestRepairViewRequest from '../../forms/request-repair/request-repair-view-request';
import requestViewer from '../../components/form/request-viewer';
import { evaluationStatusLabel, evaluationStatusColour } from '../../filters/evaluation-status';
import { renderDetailsFnByType } from '../../services/helpers/render-details.js';
import { getGenericRequestContent } from '../../services/api/forms.js';
import { generate as generatePdf } from '../../services/pdf';

export default {
  name: 'RequestDetailsSection',
  components: {
    CollapsibleListItem,
    FileDownloadLink,
    outsideThePetViewRequest,
    requestRepairViewRequest,
    StatusNotice,
    requestViewer,
    PorBtn,
    Loader,
  },
  filters: {
    evaluationStatusLabel,
    evaluationStatusColour,
  },
  mixins: [withEchoLabels],
  props: {
    requestDetails: { required: true },
  },
  data() {
    return {
      generatingPdf: false,
    };
  },
  computed: {
    ...mapGetters({
      requestsByTypeId: 'REQUESTS_BY_TYPE_ID',
      visitsByUcrn: 'VISITS_LIST_BY_UCRN',
    }),
    createdDate() {
      const req = this.requestDetails.request;

      return req.timestampCreation ?? req.creationTime;
    },
    lastModDate() {
      const req = this.requestDetails.request;

      return req.timestampModification ?? req.modificationTime ?? req.timestampCreation ?? req.creationTime;
    },
    combinedRequestDetailsAndVessel() {
      return {
        ...this.requestDetails,
        ...this.requestVessel,
      };
    },

    renderedDetails() {
      return this.requestDetails.request?.renderedDetails ?? [];
    },

    renderedEvaluationDetails() {
      return this.requestDetails.request?.renderedEvaluationDetails ?? [];
    },

    requestVessel() {
      const { id, type, request } = this.requestDetails ?? {};
      const vessel = this.requestsByTypeId?.[type]?.[id]?.request?.vessel ?? this.visitsByUcrn?.[request?.ucrn]?.vessel;

      return { vessel };
    },
    agentAttachments() {
      return this.getAttachmentsFor('AGENT');
    },
    harbourMasterAttachments() {
      return this.getAttachmentsFor('HARBOUR_MASTER');
    },
  },
  methods: {
    evaluationStatusLabel,

    getAttachmentURL({ requestType, requestId, attachmentId }) {
      const type = requestType.toUpperCase();
      const requestTypes = {
        MOORING: 'permission-outside-pet',
        REPAIR: 'request-repair',
      };

      const path = requestTypes[type] ?? 'generic-applications';

      return `/api/hamis/forms/${path}/${requestId}/attachments/${attachmentId}`;
    },

    getAttachmentsFor(uploadedBy) {
      const attachments = this.requestDetails.request.attachments ?? [];

      return Array.isArray(attachments[uploadedBy]) && attachments[uploadedBy] ? attachments[uploadedBy] : [];
    },

    /**
     * Event handler for when copy button is clicked
     */
    async onCopyClick() {
      if (!this.requestDetails?.request) {
        return;
      }

      const {
        commId,
        applicant,
        agentShortName,
        evaluationStatus,
        renderedDetails,
        renderedEvaluationDetails,
        timestampCreation,
        timestampModification,
        ...copy
      } = cloneDeep(this.requestDetails.request);

      const requestType = this.requestDetails.type.toLowerCase();
      if (!['mooring', 'repair'].includes(requestType)) {
        delete copy.type;
      }

      const { data } = !['mooring', 'repair'].includes(requestType) ? await getGenericRequestContent(commId) : {};
      Object.assign(copy, data);

      if (copy.attachments) {
        if (Object.keys(copy.attachments).length > 0) {
          copy.attachments = copy.attachments.AGENT ?? [];
        } else {
          delete copy.attachments;
        }
      }

      this.$router.push({
        name: `new-request-${requestType}`,
        params: { copy },
      });
    },

    /**
     * Event handler for when download button is clicked
     *
     * Generates a PDF from the request and opens it in a new window/tab.
     */
    async onPdfBtnClick() {
      if (!this.requestDetails) {
        return;
      }

      // disables download button and shows spinner
      this.generatingPdf = true;

      try {
        // case folding for request type and make a shallow copy that we can
        // mutate without messing up the reactivity
        const requestType = this.requestDetails.type.toUpperCase();
        const copy = { ...this.requestDetails };

        // special treatment for BAR requests to make them look like
        // generic applications so PDF rendering doesn't have to worry
        // about the distinction
        if (Object.keys(renderDetailsFnByType).includes(requestType)) {
          // re-create data structure of rendered details that is otherwise
          // taken care of by CAS
          const renderDetailsFn = renderDetailsFnByType[requestType];
          const renderedDetails = renderDetailsFn(this.requestDetails);

          // stick the rendered details on the same place CAS puts them
          Object.assign(copy, {
            request: {
              renderedDetails,
              ...copy.request,
            },
          });

          // pretend the old 'stateReason' is a field of the evaluation details
          // which get rendered in the same place (just not bold, but that i can live with)
          if (this.requestDetails.request?.stateReason) {
            Object.assign(copy, {
              request: {
                ...copy.request,
                renderedEvaluationDetails: [
                  {
                    type: 'SECTION',
                    items: [
                      {
                        type: 'FIELD',
                        label: this.$t('view.request.hcc.state.reason'),
                        value: this.requestDetails.request.stateReason,
                      },
                    ],
                  },
                ],
              },
            });
          }
        }

        // pass the data to the PDF service which kicks of the worker that
        // does the actual PDF generating and returns a promise that resolves
        // in a blob containing the PDF data
        const blob = await generatePdf(copy);
        const url = URL.createObjectURL(blob);

        // TODO: Refactor opening the PDF once Native FileSystem API promotes from origin trial
        // see: https://web.dev/native-file-system

        // prepare filename parts
        const title = this.$t(`${requestType.toLowerCase()}.title`);
        const date = this.formatDate(new Date());
        const state = evaluationStatusLabel(copy.request.evaluationStatus);

        // create a link, set the href to an object URL created from the Blob,
        // then click it
        const doc = document;
        const a = doc.createElement('a');
        a.download = `${title} - ${date} - ${state}.pdf`;
        a.href = url;
        a.dataset.test = 'pdf-download';
        a.style.display = 'none';

        // make sure to clean up after ourselves
        a.addEventListener('click', () => {
          setTimeout(() => URL.revokeObjectURL(url), 2 * 1000);
          a.remove();
        });

        // we actually append it to the DOM. this is not really necessary,
        // but it allows us to intercept the click event during CT's
        // and make assertions about the generated blob.
        doc.body.appendChild(a);
        a.click();

        // reset download button
        this.generatingPdf = false;
      } catch (e) {
        // something went awry, reset download button and show notification
        this.generatingPdf = false;
        this.$notify({
          type: 'warning',
          title: e.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~poronline-shared-vue-components/styles/variables';

.btn-copy,
.btn-download {
  $height: 1.3rem;

  .spinner {
    display: inline;
  }

  svg {
    display: inline;
    height: $height;
    fill: $primary;
  }

  span {
    vertical-align: middle;
  }
}
</style>
