import workerUrl from 'worker?name=pdf!../workers/pdf/worker.js'; // see: https://github.com/GoogleChromeLabs/worker-plugin#loader

/**
 * Generate a PDF from a Customer Application
 *
 * @param {Object} application
 * @param {string} application.type
 * @param {object} application.request
 * @returns {Promise<Blob<{ type: 'application/pdf' }>>}
 */
export const generate = (application) =>
  new Promise((resolve, reject) => {
    try {
      // create a new Worker instance that is going to generate the PDF
      const worker = new Worker(workerUrl, { type: 'module' });

      // register handler for when a message arrives from the worker
      worker.addEventListener('message', (evt) => {
        // switch over the message type
        switch (evt.data?.type) {
          case 'pdf':
            // create a PDF Blob from the ArrayBuffer contained in the message and resolve the promise with it
            resolve(new Blob([evt.data.buf], { type: 'application/pdf' }));
            break;

          case 'err':
            // reject with the error contained in the message
            reject(evt.data.err);
            break;
        }
      });

      // send the application data off to the worker to generate the PDF
      worker.postMessage({ application });
    } catch (e) {
      // reject if something fails for whatever reason
      reject(e);
    }
  });
