<template>
  <request-viewer :content="content" />
</template>

<script>
import requestViewer from '../../components/form/request-viewer';
import { renderRepairApplication } from '../../services/helpers/render-details.js';

export default {
  name: 'requestRepairViewRequest',
  components: { requestViewer },
  props: {
    requestDetails: { required: true },
  },
  computed: {
    content() {
      if (!this.requestDetails || !this.requestDetails.request) {
        return [];
      }

      return renderRepairApplication(this.requestDetails);
    },
  },
};
</script>
