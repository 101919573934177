export default {
  name: 'withFormLabels',
  methods: {
    typeLabel(type) {
      return (
        {
          REPAIR: this.$t('repair.title'),
          MOORING: this.$t('mooring.title'),
          TRANSPORT: this.$t('transport.title'),
          OUDEMAAS: this.$t('oudemaas.title'),
        }[type?.toUpperCase()] || this.$t('form.unknown')
      );
    },
  },
};
