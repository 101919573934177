<template>
  <div class="row page-section" :class="className">
    <div class="col">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageSection',
  props: {
    white: Boolean,
  },
  computed: {
    className() {
      return this.white ? 'bg-white' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~poronline-shared-vue-components/styles/variables';
@import '~poronline-shared-vue-components/styles/type';

$page-section-title-margin-t: map-get($spacers, 4);
$page-section-title-margin-b: map-get($spacers, 4);

$page-section-bg-padding: map-get($spacers, 3);
$page-section-bg-margin: map-get($spacers, 3);

.page-section {
  margin-bottom: map-get($spacers, 3);

  &__title {
    @extend %h2;
    margin-top: $page-section-title-margin-t;
    margin-bottom: $page-section-title-margin-b;
  }

  // When we have a background
  &[class*='bg-'] {
    padding-left: map-get($spacers, 4);
    padding-right: map-get($spacers, 4);
    margin-bottom: map-get($spacers, 5);
  }
}
</style>
