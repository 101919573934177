<template>
  <div class="request-viewer" :class="level > 1 ? 'ml-2 pl-2 pt-2 border-left' : ''">
    <div class="row pb-2" :data-item-type="item.valueType" v-for="(item, index) in content" :key="index">
      <div :class="{ 'col-12': true, 'pb-3': index < content.length - 1 }" v-if="item.items">
        <template v-if="item.legend">
          <h5 v-if="level < 1">{{ item.legend }}</h5>
          <p v-else>{{ item.legend }}</p>
        </template>
        <div v-if="item.items" :class="item.legend ? '' : 'mt-2'">
          <request-viewer :content="item.items" :level="level + 1" />
        </div>
      </div>
      <template v-else>
        <div v-if="item.valueType !== 'BOOLEAN-CHECKBOX'" class="col-5 pr-3">{{ item.label }}</div>
        <div v-if="item.valueType === 'DATE'" class="col-7">{{ formatDate(item.value) }}</div>
        <div v-else-if="item.valueType === 'BOOLEAN'" class="col-7">
          {{ $t(item.value ? 'generic.options.yes' : 'generic.options.no') }}
        </div>
        <div v-else-if="item.valueType === 'BOOLEAN-CHECKBOX'" class="col-12 pr-3">
          <b-form-checkbox class="boolean-checkbox" :checked="item.value" disabled>
            {{ item.label }}
          </b-form-checkbox>
        </div>
        <div v-else class="col-7">{{ item.value }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';

export default {
  name: 'requestViewer',
  components: { BFormCheckbox },
  props: {
    level: {
      type: Number,
      default: 0,
    },
    content: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~poronline-shared-vue-components/styles/variables';

// add extra vertical spacing between a row of a "non"-checkbox and a checkbox row
div.row:not([data-item-type='BOOLEAN-CHECKBOX']) + div.row[data-item-type='BOOLEAN-CHECKBOX'] {
  margin-top: $spacer / 2;
}

// do "not" use a bottom margin for a checkbox that is on the last row
div:last-child[data-item-type] .boolean-checkbox {
  margin-bottom: 0;
}
</style>

<style lang="scss">
@import '~poronline-shared-vue-components/styles/variables';

.request-viewer {
  .custom-checkbox.boolean-checkbox {
    // add a bottom margin to each row with a checkbox
    margin-bottom: $spacer / 2;

    .custom-control-input:disabled {
      ~ .custom-control-label {
        color: inherit; // use the inherited text color instead of normal color used for label of a `:disabled` checkbox
      }

      &:checked {
        // WORKAROUND: override the border and background color of "vue-shared-components" / (twitter) "bootstrap"
        // checkbox that is both `:disabled` and `:checked`
        ~ .custom-control-label::before {
          border-color: $gray-light-1;
          background-color: $gray-light-1;
        }

        ~ .custom-control-label::after {
          // WORKAROUND: override the background image of "vue-shared-components" / (twitter) "bootstrap" so that the
          // `<svg>` of the `background-image` is using a transparent background instead of using a filled background
          background-image: url("data:image/svg+xml,%3Csvg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg transform='translate(1.000000, 1.000000)'%3E%3Cpolygon id='path-3' fill='white' points='4.47893633 8.3385634 3 9.86888128 7.9153474 15 17 5.53153217 15.5222278 4 7.91651577 11.9270439'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
        }
      }
    }
  }
}
</style>
