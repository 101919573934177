import i18n from '../../i18n.js';

export const renderRepairApplication = (application) => {
  const { ucrn, request, vessel } = application;
  const { berth } = request;

  const shipVisit = {
    type: 'SECTION',
    legend: i18n.t('view.visit.title'),
    items: [
      {
        type: 'FIELD',
        label: i18n.t('view.visit.ucrn'),
        value: ucrn,
      },
      {
        type: 'FIELD',
        label: i18n.t('view.ship.name'),
        value: vessel?.name,
      },
      {
        type: 'FIELD',
        label: i18n.t('generic.abbr.imo'),
        value: vessel?.imo,
      },
      {
        type: 'FIELD',
        label: i18n.t('view.visit.movement.berth'),
        value: berth?.name ?? i18n.t('view.visit.unknown'),
      },
    ],
  };
  const activities = {
    type: 'SECTION',
    legend: i18n.t('repair.activities.title'),
    items: [
      {
        type: 'FIELD',
        label: i18n.t('repair.activities.type.label'),
        value: request.type,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.activities.nature.labelView'),
        value: request.description,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.activities.location.label'),
        value: request.locationOnBoard,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.activities.locationExact.label'),
        value: request.locationOnBoardText,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.activities.start.label'),
        valueType: 'DATE',
        value: request.from,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.activities.end.label'),
        valueType: 'DATE',
        value: request.until,
      },
    ],
  };

  if ('boolean' === typeof request.ownerPermission) {
    activities.items.push({
      type: 'FIELD',
      label: i18n.t('repair.activities.permission.labelView'),
      valueType: 'BOOLEAN',
      value: request.ownerPermission,
    });
  }

  activities.items.push({
    type: 'FIELD',
    label: i18n.t('repair.activities.crew.label'),
    value: request.ownCrew ? i18n.t('repair.activities.crew.options.true') : request.crewDescription,
  });

  const otherQuestions = {
    type: 'SECTION',
    legend: i18n.t('repair.other.title'),
    items: [
      {
        type: 'FIELD',
        label: i18n.t('repair.other.propulsion'),
        valueType: 'BOOLEAN',
        value: request.hasMainPropulsionAvailable,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.other.safetyStatement.question'),
        valueType: 'BOOLEAN',
        value: request.hasSecurityAndHealthStatement,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.other.hazardousSubstances'),
        valueType: 'BOOLEAN',
        value: request.hasHazardousSubstancesWithinSafetyParameter,
      },
      {
        type: 'FIELD',
        label: i18n.t('repair.other.lng'),
        valueType: 'BOOLEAN',
        value: request.lngDrivenShip,
      },
      ...(request.lngDrivenShip
        ? [
            {
              type: 'SECTION',
              items: [
                {
                  type: 'FIELD',
                  label: i18n.t('repair.lng.proximityTanks'),
                  valueType: 'BOOLEAN-CHECKBOX',
                  value: request.lngWorkInProximityOfTanks,
                },
                {
                  type: 'FIELD',
                  label: i18n.t('repair.lng.quantity.label'),
                  value: formattedCubicMeter(request.lngQuantityOnBoard),
                },
                {
                  type: 'FIELD',
                  label: i18n.t('repair.lng.location.label'),
                  value: request.lngBunkerTanksLocation,
                },
                {
                  type: 'FIELD',
                  label: i18n.t('repair.lng.bunker'),
                  valueType: 'NUMBER',
                  value: formattedMBar(request.lngBunkerTanksMBarPressure),
                },
                {
                  type: 'FIELD',
                  label: i18n.t('repair.lng.valve'),
                  valueType: 'NUMBER',
                  value: formattedMBar(request.lngSafetyValveOpeningsMBarPressure),
                },
                {
                  type: 'FIELD',
                  label: i18n.t('repair.lng.boilOff'),
                  valueType: 'BOOLEAN-CHECKBOX',
                  value: request.lngBoilOffControlOperational,
                },
                {
                  type: 'FIELD',
                  label: i18n.t('repair.lng.emission'),
                  valueType: 'BOOLEAN-CHECKBOX',
                  value: request.lngEmissionPreventionMeasuresTaken,
                },
              ],
            },
          ]
        : []),
    ],
  };

  return [shipVisit, activities, otherQuestions];
};

export const renderMooringApplication = (application) => {
  const { ucrn, request, vessel } = application;
  const { berth } = request;

  return [
    {
      type: 'SECTION',
      legend: i18n.t('view.visit.title'),
      items: [
        {
          type: 'FIELD',
          label: i18n.t('view.visit.ucrn'),
          value: ucrn,
        },
        {
          type: 'FIELD',
          label: i18n.t('view.ship.name'),
          value: vessel?.name,
        },
        {
          type: 'FIELD',
          label: i18n.t('generic.abbr.imo'),
          value: vessel?.imo,
        },
        {
          type: 'FIELD',
          label: i18n.t('view.visit.movement.berth'),
          value: berth?.name ?? i18n.t('view.visit.unknown'),
        },
      ],
    },
    {
      type: 'SECTION',
      legend: i18n.t('mooring.reason.title'),
      items: [
        {
          type: 'FIELD',
          label: i18n.t('mooring.reason.label'),
          value: request.reason,
        },
      ],
    },
    {
      type: 'SECTION',
      legend: i18n.t('mooring.tanks.title'),
      items: [
        {
          type: 'FIELD',
          label: i18n.t('mooring.tanks.label'),
          value: i18n.t(`mooring.shipCleanState.${request.shipCleanState}`),
        },
        ...('NOT_CLEAN' === request.shipCleanState
          ? [
              {
                type: 'FIELD',
                label: i18n.t('mooring.tanks.slop'),
                value: request.slopTanksDescription || '-',
              },
              {
                type: 'FIELD',
                label: i18n.t('mooring.tanks.title'),
                value: request.cargoTanksDescription || '-',
              },
              {
                type: 'FIELD',
                label: i18n.t('mooring.tanks.inert.labelView'),
                valueType: 'BOOLEAN',
                value: request.hasInertTanks,
              },
              ...(request.hasInertTanks
                ? [
                    {
                      type: 'FIELD',
                      label: i18n.t('mooring.tanks.inert.label'),
                      value: request.inertTanksDescription,
                    },
                  ]
                : []),
            ]
          : 'CLEAN' === request.shipCleanState
          ? [
              {
                type: 'SECTION',
                items: [
                  {
                    type: 'FIELD',
                    label: i18n.t('mooring.tanks.number.placeholder'),
                    value: request.lastCargoDescription,
                  },
                ],
              },
            ]
          : []),
      ],
    },
    {
      type: 'SECTION',
      legend: i18n.t('mooring.gasReport.label'),
      items: [
        {
          type: 'FIELD',
          label: i18n.t('mooring.gasReport.question'),
          valueType: 'BOOLEAN',
          value: request.hasReportFromGasExpert,
        },
      ],
    },
  ];
};

export const renderDetailsFnByType = {
  REPAIR: renderRepairApplication,
  MOORING: renderMooringApplication,
};

const formattedSquareMeter = (numberValue) => numberValue && `${i18n.n(numberValue)} m²`;
const formattedCubicMeter = (numberValue) => numberValue && `${i18n.n(numberValue)} m³`;
const formattedMBar = (numberValue) => numberValue && `${i18n.n(numberValue)} mBar`;
